import shinaleto from "./image/shinaleto3.gif"
import shinazima from "./image/shinazima.gif"
import CKI from "./image/CKI.gif"
import gruz from "./image/gruz1.gif"
import diskcategory from "./image/diskcategory.gif"
import moto from "./image/moto3.gif"


const ProduCtategory = {
    list1: [
        shinaleto,
        "Летние шины бу",
        [
            "R12",
            "R13",
            "R14",
            "R15",
            "R16",
            "R17",
            "R18",
            "R19",
            "R20",
            "R21",
            "R22"
        ]
    ],
    list2: [
        shinazima,
        "Зимние шины бу", 
        [
            "R12",
            "R13",
            "R14",
            "R15",
            "R16",
            "R17",
            "R18",
            "R19",
            "R20",
            "R21",
            "R22"
        ]
    ],
    list3: [
        CKI,
        "Легкогрузовые шины бу",
        [
            "R10c",
            "R12c",
            "R13c",
            "R14c",
            "R15c",
            "R16c"
        ]
    ],
    list4: [
        gruz,
        "Грузовые шины бу",
        [
            "R8",
            "R9",
            "R10",
            "R12",
            "R15",
            "R15.3",
            "R15.5",
            "R16",
            "R16.5",
            "R17.5",
            "R18",
            "R19.5",
            "R20",
            "R21",
            "R22.5",
            "R24",
            "R25",
            "R26",
            "R28",
            "R38",
            "R508",
            "R533"
        ]
    ],
    list5: [
        diskcategory,
        "Диски и колеса бу",
        [
            "Литые",
            "Штампованные"
        ]
    ],
    list6: [
        moto,
        "Мотошины бу",
        [
            "R12",
            "R13",
            "R14",
            "R15",
            "R16",
            "R17",
            "R18",
            "R19",
            "R20",
            "R21"
        ]
    ]
}


// const ProduCtategory = {
//     list1: [
//         shinaleto,
//         "Летние шины бу",
//         [
//             ["R13",["145/70/13","155/60/13","155/65/13","155/80/13","165/65/13","165/70/13","165/80/13","175/60/13","175/70/13","175/75/13","175/80/13","185/70/13","На Matiz"]],
//             ["R14",["165/60/14","165/65/14","165/70/14","165/80/14","175/60/14","175/65/14","175/70/14","175/75/14","175/80/14","185/55/14","185/60/14","185/65/14","185/70/14","185/75/14","185/80/14","195/60/14","195/65/14","195/70/14","195/75/14","195/80/14","205/65/14","205/70/14","205/75/14"]],
//             ["R15",["165/65","175/60","175/65","175/70","175/75","175/80","185/55","185/60","185/65","185/70","185/75","185/80","195/50","195/55","195/60","195/65","195/70","195/75","195/80","205/50","205/55","205/60","205/65","205/70","205/75","205/80","215/60","215/65","215/70","215/75","215/80","225/60","225/65","225/70","225/75","225/80","235/70","235/75","255/70","265/70","265/75"]],
//             ["R16",["175/60/16","175/65/16","175/80/16","185/55/16","185/75/16","195/50/16","195/55/16","195/60/16","205/45/16","205/50/16","205/55/16","205/60/16","205/65/16","205/70/16","205/75/16","205/80/16","215/55/16","215/60/16","215/65/16","215/70/16","215/75/16","215/80/16","225/50/16","225/55/16","225/60/16","225/65/16","225/70/16","225/75/16","225/80/16","235/60/16","235/65/16","235/70/16","235/75/16","235/80/16","235/85/16","245/70/16","245/75/16","255/65/16","255/70/16","265/65/16","265/70/16","265/75/16","275/70/16","285/75/16","Шины на УАЗ","на Ниву"]],
//             "R17",
//             ["R18",["215/55/18","225/40/18","225/45/18","225/50/18","225/55/18","225/60/18","225/65/18","235/40/18","235/45/18","235/50/18","235/55/18","235/60/18","235/65/18","245/40/18","245/45/18","245/50/18","245/60/18","255/55/18","255/60/18","255/65/18","265/60/18","265/65/18","275/60/18"]],
//             ["R19",["225/55/19","235/50/19","235/55/19","245/55/19","255/50/19","255/55/19","265/50/19"]],
//             ["R20",["225/50","225/55","235/35","235/55","245/35","245/40","245/45","245/50","255/30","255/35","255/40","255/45","255/50","255/55","265/35","265/40","265/45","265/50","275/35","275/40","275/45","275/55","275/60","275/65","285/30","285/35","285/45","285/50","295/30","295/35","295/40","295/45","305/30","305/40","305/45","315/35","325/35"]],
//             "R21",
//             "R22"
//         ]
//     ],
//     list2: [
//         shinazima,
//         "Зимние шины бу", 
//         [
//             "R12",
//             ["R13",["155/70","155/80","165/70","165/80","175/65","175/70","185/70"]],
//             ["R14",["165/60","175/65","175/70","175/80","185/60","185/65","185/70","195/60","195/65","195/70","205/70"]],
//             ["R15",["145/65","155/60","165/65","175/55","175/60","175/65","185/55","185/60","185/65","195/50","195/55","195/60","195/65","195/70","205/55","205/60","205/65","205/70","205/75","215/55","215/65","215/70","225/60","225/75","235/70","235/75","245/70"]],
//             ["R16",["235/60","235/65","235/70","245/70","245/75","255/55","255/65","255/70","265/70","265/75","275/70","285/75","185/50"]],
//             "R17",
//             "R18",
//             "R19",
//             "R20",
//             "R21",
//             "R22"
//         ]
//     ],
//     list3: [
//         CKI,
//         "Легкогрузовые шины бу",
//         [
//             ["R10c",["195/50"]],
//             ["R12c",["155-12c"]],
//             ["R13c",["145-13c","155-13c"]],
//             ["R14c",["185-14c","185/75","195-14c"]],
//             ["R15c",["165/70","185-15c","185/85","195/70","195/75","205/65","205/70","215/65","215/70","225/70"]],
//             ["R16c",["175-16","175/80","185/75","185/80","195/60","195/70","195/75","205/60","205/65","205/75","215/60","215/65","215/70","215/75","225/65","225/70","225/75","235/65","245/75"]]
//         ]
//     ],
//     list4: [
//         gruz,
//         "Грузовые шины бу",
//         [
//             ["R8",["5.00-8","15*4.5-8","18*7-8"]],
//             ["R9",["6.00-9","21*8-9"]],
//             ["R10",["6.50-10"]],
//             ["R12",["5.00-12","7.00-12","27*9-12"]],
//             ["R15",["6.7-15","7.00-15","8.15-15","8.25-15","28*9-15"]],
//             ["R15.3",["10/75","12.5/80"]],
//             ["R15.5",["195/70"]],
//             ["R16",["6.50-16","7.00-16","7.50-16","215/85","225/75"]],
//             ["R16.5",["10.00-16.5","12.00-16.5"]],
//             ["R17.5",["9.50-17.5","205/75","215/75","235/75","245/70"]],
//             ["R18",["12.50/80"]],
//             ["R19.5",["245/70","265/70"]],
//             ["R20 (R508)",["7.50-20","8.25-20","9.00-20","10.00-20","11.00-20","11.2-20","12.00-20","12.00/80","14.00-20(370-508)","16/70 (405/70)"]],
//             ["R21 (R533)",["1300/530",'425/85']],
//             ["R22.5",["275/70","275/80","295/80","315/60","315/70","315/80","385/60","385/65","445/65","500/60","10.00-22.5","11.00-22.5","12.00-22.5","13.00-22.5"]],
//             ["R24",["12.00-24","14.00-24","16.9-24","21.3-24","405/70"]],
//             ["R25",["15.5-25","17.5-25"]],
//             ["R26",["23.1-26","18.4-26"]],
//             ["R28",["16.9-28"]],
//             ["R38",["15.5-38"]]
//         ]
//     ],
//     list5: [
//         diskcategory,
//         "Диски и колеса бу",
//         [
//             "Литые",
//             "Штампованные"
//         ]
//     ],
//     list6: [
//         moto,
//         "Мотошины бу",
//         [
//             "R15",
//             "R16",
//             "R17",
//             "R18",
//             "R19",
//             "R20",
//             "R21"
//         ]
//     ]
// }

export default ProduCtategory