import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Table } from "antd";

const columns = [
  {
    title: "Димаметр",
    dataIndex: "diameter",
    key: "diameter",
  },
  {
    title: "Стальные руб/колесо",
    dataIndex: "SteelPrice",
    key: "SteelPrice",
  },
  {
    title: "Литые руб/колесо",
    dataIndex: "CastPrice",
    key: "CastPrice",
  },
];

const data = [
  {
    key: "1",
    diameter: "R13",
    SteelPrice: "350-600",
    CastPrice: "400-1000",
  },
  {
    key: "2",
    diameter: "R14",
    SteelPrice: "400-700",
    CastPrice: "450-1100",
  },
  {
    key: "3",
    diameter: "R15",
    SteelPrice: "400-900",
    CastPrice: "500-1200",
  },
  {
    key: "4",
    diameter: "R16",
    SteelPrice: "500-1000",
    CastPrice: "600-1300",
  },
  {
    key: "5",
    diameter: "R17",
    SteelPrice: "600-1200",
    CastPrice: "700-1500",
  },
  {
    key: "6",
    diameter: "R18",
    CastPrice: "800-1700",
  },
  {
    key: "7",
    diameter: "R19",
    CastPrice: "900-1900",
  },
  {
    key: "8",
    diameter: "R20",
    CastPrice: "1000-2000",
  },
  {
    key: "9",
    diameter: "R21",
    CastPrice: "1100-2200",
  },
  {
    key: "10",
    diameter: "R22",
    CastPrice: "1100-2400",
  },
];

function EditingDiscs() {
  return (
    <Row gutter={[0, 32]}>
      <Col span={24}>
        <h3>Правка дисков</h3>
        <p>
          Правка, прокатка дисков входит в услуги нашей компании Sintekh.
          Вследствие спешки на дороге и по причине неаккуратного вождения может
          нарушаться первоначальная геометрия диска. Степень дефектов бывает
          разной. Это зависит от того, с какой скоростью вы двигались на
          автомобиле и от состояния дорожного покрытия. Также важен материал, из
          которого был выполнен автодиск. Легко поддаются ремонту стальные
          штампованные диски. Вернуть им первоначальный вид довольно легко.
          Восстанавливать литые автодиски сложнее и это будет занимать больше
          времени. Стоимость наших услуг зависит от степени повреждения
          автодиска. Но мы всегда готовы пойти вам навстречу и правка дисков
          авто обойдется по вполне доступной цене.
        </p>
      </Col>
      <Col xs={{span: 24}} md={{span: 11}}>
        <p style={{fontSize: "18px"}}>Стоимость:</p>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          size="small"
        />
      </Col>
      <Col xs={{span: 24}} md={{span: 12, offset: 1}}>
        <h2>Как у нас происходит правка литых дисков</h2>
        <p>
          Правка дисков колес позволит убрать дефекты независимо от их вида. У
          нас особый подход к каждому конкретному случаю. Прежде чем начать
          ремонт, мы проводим диагностику. Затем устанавливаем уровень и
          характер повреждения автодиска на балансировочном стенде. Далее правка
          автомобильных дисков происходит следующим образом:
        </p>
        <ul>
          <li>Устранение имеющихся повреждений;</li>
          <li>Окрашивание диска, если необходимо.</li>
        </ul>
        <p>
          Наш главный приоритет – это ваша безопасность на дороге. Поэтому мы
          ремонтируем только те диски, дальнейшая эксплуатация которых вам не
          навредит.
        </p>
        <p>
          Правка литых дисков цены в Москве не высокие. Зависят от степени
          повреждения автодиска. Сначала диагностируем диск, чтобы определить
          дефекты. На основе данной процедуры будет определена стоимость услуги.
          Правка алюминиевых дисков – это довольно сложный процесс. Выполняется
          нашими опытными специалистами. Поэтому мы можем гарантировать вам
          безопасность на дороге.
        </p>
      </Col>
    </Row>
  );
}

export default EditingDiscs;
