import styled from "styled-components";

export const HeaderStyle = styled.div`
    margin-top: 20px;

     .divLogo {
        max-width: 176px;
        margin: auto;

        & img {
            width: 100%;
        }
    }

    .headerInfoContact {
        width: auto;

        & a {
            float: left;
            color: #e20606; 
            font-size: 20px;
            font-weight: 500;
    
            :hover {
                text-decoration: underline;
            }
        }
    
        & ul {
            float: left;
            margin-bottom: 0;

            & li {
                
                color: #e20606; 
                margin-top: 3px;
                font-size: 18px;
        
                & p {
                    
                    font-size: 12px;
                    color: #222222;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .headerInfoContact {
            margin: auto;
            width: 180px;

            & ul {
                margin-top: -8px;
            }
        }
    }

    .mailHeader {
        text-decoration: underline;
        color: #222222;
        font-size: 14px;
        padding-left: 10px;
        padding-bottom: 5px;
    
        :hover {
            color: #e20606;
        }
    }

`;

export const BottomPartStyle = styled.div`

    .BottomPart img {
        color: #e20606;
    }

    .BottomPart button {
        all: unset;
        cursor: pointer;
        display: none;
    }

    @media screen and (max-width: 576px) {
        .BottomPart button {
            display: block;
        }
    }

    @media screen and (max-width: 767px) {
        .iconHeader {
            display: none;
        }
    } 

    .delivery {
        width: 115px;
        height: 60px;
    }

    .delivery div {
        width: 55px;
        height: 55px;
        border: solid 1px #e20606;
        border-radius: 50px;
    }

    .delivery img {
        width: 45px;
        height: 40px;
        border-radius: 25px;
        margin: 5px 2px;
    }

    .delivery p {
        width: 50px;
        height: 40px;
        float: right;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.623);
        margin-top: -50px;
        line-height: 14px;
    }


    .creditCards {
        width: 115px;
        height: 60px;
    }

    .creditCards div {
        width: 55px;
        height: 55px;
        border: solid 1px #e20606;
        border-radius: 50px;
    }

    .creditCards img {
        width: 40px;
        height: 40px;
        margin: 5px 5px;
    }

    .creditCards p {
        width: 50px;
        height: 40px;
        float: right;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.623);
        margin-top: -52px;
        line-height: 14px;
    }


    .like {
        width: 115px;
        height: 60px;
    }

    .like div {
        width: 55px;
        height: 55px;
        border: solid 1px #e20606;
        border-radius: 50px;
    }

    .like img {
        width: 30px;
        height: 30px;
        margin: 9px 11px;
    }

    .like p {
        width: 50px;
        height: 40px;
        float: right;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.623);
        margin-top: -52px;
        line-height: 14px;
    }

`;