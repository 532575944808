import React from "react";

function Delivery() {
    return(
        <div className="ContentDelivery">
            <h3>Доставка</h3>
            <p>Самовывоз:</p>
            <p>
                Вы можете забрать заказ в одном из наших пунктов самовывоза. Круглосуточно!
            </p>
            <h6>Курьером по Москве в пределах МКАД:</h6>
            <p>Доставка по Москве осуществляется с ежедневно</p>
            <p>
                При заказе на сумму от 10000 руб доставка БЕСПЛАТНО. При заказе на сумму до 10000 - стоимость доставки расчитывается согласно тарифу курьерской службы.
            </p>
            <h6>Курьером за МКАД:(не более 30км)</h6>
            <p>Доставка осуществляется с ежедневно.</p>
            <p>Стоимость доставки расчитывается согласно тарифу курьерской службы.</p>
        </div>
    )
}

export default Delivery