import React from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import { HistoryOfCompanyStyle } from "./HistoryOfCompanyStyle";

function HistoryOfCompany() {
  return (
    <Row>
      <Col span={22} offset={1}>
        <HistoryOfCompanyStyle>
          <Row>
            <Col span={18}>
              <h2>Компания Shintekh:</h2>
              <h3>услуги шиномонтажа круглосуточно</h3>
              <p>
                Наша компания работает с 2007 года. Мы предоставляем услуги шиномонтажа, продаем шины и диски, заправляем автомобильные и домашние кондиционеры, заменяем тормозные колодки, занимаемся вулканизаций и аргонной сваркой.
              </p>
              <p>
                У нас можно приобрести новые комплектующие для автомобиля по доступной цене. Все изделия у нас проходят тщательную проверку. Имеют необходимую сертификацию. Также вы можете воспользоваться услугами наших специалистов, которые в краткие сроки восстановят поврежденные комплектующие.
              </p>
              <p>
                За период нашей 10-летней работы у нас появилось очень много клиентов, которые довольны нашими услугами и постоянно возвращаются именно к нам. Мы работаем 24 часа в сутки и способны обеспечить качественный сервис.
              </p>
            </Col>
            <Col span={18}>
              <h6>Преимущества сотрудничества с нашей компанией Shintekh:</h6>
              <ul>
                <li>
                  Все товары у нас проходят проверку, после которой мы уверены в их безупречном качестве;
                </li>
                <li>
                  Мы используем дорогое специализированное оборудование, позволяющее максимально эффективно выполнить работу;
                </li>
                <li>
                  Наши мастера имеют необходимые знания и навыки по шиномонтажу и другим ремонтным работам;
                </li>
                <li>
                  За 10 лет работы мы ни разу не подвели своих клиентов, благодаря чему пользуемся их уважением и доверием
                </li>
                <li>
                  Оплата у нас возможна любым способом: наличными, переводом на банковскую карту и другими не менее удобными вариантами.
                </li>
              </ul>
              <p>
                Нашими услугами пользуются юридические лица и корпоративные клиенты. Мы всегда вовремя обеспечиваем доставку товара, услуги выездного шиномонтажа. Если у вас случится поломка на дороге, мы приедем к вам в течение 30 минут независимо от времени суток. Вам не нужно будет искать магазины, чтобы купить запчасти. Мы все привезем с собой.
              </p>
              <p>
                Наша компания входит в число лидеров по услугам шиномонтажа в Москве. У нас есть склады и магазины, где можно приобрести новые и б/у шины, купить диски. Мы имеем разное специализированное оборудование, которое позволяет проводить такие сложные работы как сваривание аргоном, ремонт литых и кованых дисков, заправку кондиционеров.
              </p>
              <p>
                Наша компания Shintekh постоянно расширяет ассортимент товаров, а также сферу деятельности. Мы все время совершенствуемся, что позволяет нам обеспечивать высокое качество наших услуг и гарантировать вам безопасность и комфорт на дороге.
              </p>
            </Col>
          </Row>
        </HistoryOfCompanyStyle>
      </Col>
    </Row>
  );
}

export default HistoryOfCompany;
