import React from "react";

function Refund() {
    return(
        <div className="ContentRefund">
            <h3>Возврат</h3>
            <p>УСЛОВИЯ ВОЗВРАТА ПОКУПКИ</p>
            <ul>
                <li>Если товар надлежащего качества Вам не подошел, Вы вправе отказаться от покупки при получении, в этом случае курьеру оплачиваются транспортные расходы в размере 300 рублей, (при доставке за МКАД 500 рублей).</li>
                <li>Если товар надлежащего качества Вам не подошел, но Вы уже приняли и оплатили его, Вы так же вправе отказаться от покупки, в этом случае стоимость вывоза товара нашими силами по г. Москва составит 300 рублей (за МКАД 500 рублей).</li>
                <li>Вы так же можете привезти товар самостоятельно, если доставка была бесплатной, деньги возвращаются за минусом денежных средств, затраченных на доставку к Вам. Если же Вы оплачивали доставку товара при получении, возвращается только стоимость товара.</li>
            </ul>
        </div>
    )
}

export default Refund