import styled from 'styled-components';


export const CreateProductStyle = styled.div`
  width: 100%;
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

`;