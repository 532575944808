import React, { useMemo } from "react";
import "antd/dist/antd.css";
import { Row, Col, Result, Button } from "antd";
import MenuBreadcrumb from './../MenuBreadcrumb/MenuBreadcrumb';
import { useHistory, useLocation } from "react-router-dom";

function Thanks() {
    const pageName = ["/", "завершен"];
    const history = useHistory()

    function useQuery() {
        const { search } = useLocation();
      
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const onClick = (url) => {

        history.push(url)
    }
    

  return (
    <Row gutter={[0, 16]} style={{ marginTop: "32px" }}>
      <Col span={23} offset={1}>
        <MenuBreadcrumb PathPageName={[pageName]} />
      </Col>
      <Col xs={{ span: 22, offset: 1 }} style={{marginTop: "70px", marginBottom: "150px"}}>
        {
            (useQuery().get("status") === "ok") ?
            <Result
                status="success"
                title="Спасибо за покупку"
                subTitle="Всё прошел успешно покупка завершен"
                extra={[
                <Button type="primary" key="goToBack" onClick={() => onClick("/")}>
                    вернутся главной страницы
                </Button>,
                ]}
            />
            :
            <Result
                status="warning"
                title="Что-то пошло не так"
                subTitle="Вернитесь корзину и попробуйте снова, или свяжитесь с нами"
                extra={[
                <Button type="primary" key="goToBack" onClick={() => onClick("/")}>
                    главной
                </Button>,
                <Button type="primary" key="basket" onClick={() => onClick("/basket")}>
                    корзина
                </Button>,
                ]}
            />
        }
      </Col>
    </Row>
  );
}

export default Thanks;
