import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import delivery from "./icon/delivery icon delivery icon.png";
import creditCards from "./icon/credit-cards.png";
import like from "./icon/like.png";
import { Link } from "react-router-dom";
import { ItBasket } from "./ItBasket";
import { IconVnvelope } from "./icon";
import imgLogo from "./image/IMG-20210604-WA0002.jpg";
import { universalRequest } from "../Admin/ControlLinkMessengers";
import Search from "./Search/Search";
import { HeaderStyle, BottomPartStyle } from "./HeaderStyle";

const TopPart = () => {
  const [phoneState, setPhoneState] = useState("");
  const [emailState, setEmailState] = useState("");

  useEffect(() => {
    universalRequest("get", "/admin/massengers-contacts/phone").then(
      (result) => {
        if (result && result.textBody.length === 11) {
          let phone = result.textBody;
          setPhoneState(
            `${phone[0]}(${phone[1]}${phone[2]}${phone[3]})${phone[4]}${phone[5]}${phone[6]}-${phone[7]}${phone[8]}-${phone[9]}${phone[10]}`
          );
        }
      }
    );
    universalRequest("get", "/admin/massengers-contacts/email").then(
      (result) => {
        if (result) {
          setEmailState(result.textBody);
        }
      }
    );
  }, []);

  return (
    <Row>
      <Col xs={24} md={14}>
        <Row justify="center">
          <Col xs={{span: 14}} md={{span: 24}}>
              <div className="headerInfoContact">
                <a href={`tel: +${phoneState}`} className="phoneHeader">
                  {phoneState}
                </a>
                <ul>
                  <li>
                    <p>Пн—Вс Круглосуточно</p>
                  </li>
                </ul>
              </div>
          </Col>
        </Row>
        <Row justify="center" style={{marginTop: "-10px", paddingBottom: "8px"}}>
          <Col md={{offset: 0, span: 24}}>
            <IconVnvelope 
              size={14}
              style={{
                marginTop: "-1px",
                float: "left",
                color: "#e20606",
              }}
            />
            <a href={`mailto: ${emailState}`} className="mailHeader">
              {emailState}
            </a>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={9}>
        <ItBasket size={30} />
      </Col>
    </Row>
  );
};

const BottomPart = () => {
  return (
    <BottomPartStyle>
      <Row>
        <Col xs={{span: 22, offset: 1}} md={{span: 8, offset: 0}} lg={11}>
          <Search />
        </Col>
        <Col md={{span: 15, offset: 1}} lg={{span: 12, offset: 1}}>
          <Row className="iconHeader">
            <Col md={8}>
              <div className="delivery">
                <div>
                  <img src={delivery} alt="" />
                </div>
                <p>Быстрая доставка</p>
              </div>
            </Col>
            <Col md={8}>
              <div className="creditCards">
                <div>
                  <img src={creditCards} alt="" />
                </div>
                <p>Доступные способы оплаты</p>
              </div>
            </Col>
            <Col md={8}>
              <div className="like">
                <div>
                  <img src={like} alt="" />
                </div>
                <p>Свыше 10 000+ товаров</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </BottomPartStyle>
  );
};

function Header() {
  return (
    <HeaderStyle>
      <Row  justify="space-around">
        <Col lg={6} md={6} xs={24}>
          <div className="divLogo">
            <Link to="/">
              <img src={imgLogo} alt="shintekh" />
            </Link>
          </div>
        </Col>
        <Col lg={18} md={18} xs={24}>
          <TopPart />
          <BottomPart />
        </Col>
      </Row>
    </HeaderStyle>
  );
}

export default Header;
