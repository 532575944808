import styled from "styled-components";


export const AddAReviewStyle = styled.div`
    margin-top: 16px;

    h6 {
        color: black;
        text-align: center;
    }
`;

